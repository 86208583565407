<template>
  <div v-if="isVisible" class="gwp-line-item">
    <slot :click-handler="clickHandler" />
  </div>
</template>

<script>
export default {
  computed: {
    isVisible() {
      return this.$store.state.gwp?.visible;
    },
  },
  methods: {
    clickHandler(product) {
      this.$store.dispatch("gwp/addToCart", product);
    },
  },
};
</script>
