<script>
export default {
  props: {
    elId: {
      type: String,
      required: true,
    },
  },
  methods: {
    goto() {
      const element = document.querySelector("#baskets-subscriptions");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
  render() {
    return this.$slots.default({
      goto: this.goto,
    });
  },
};
</script>
