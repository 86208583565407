<template>
  <div id="accordion-basic" class="mt4 collection-sidebar-menu">
    <label
      class="ttu mb3 nt3"
      :class="[is_mobile ? 'sidebar-title accordion-head' : 'hidden']"
      for="menu"
      aria-expanded="false"
    >
      Filters
    </label>
    <div :class="[is_mobile ? 'accordion-body visuallyhidden' : '']">
      <div v-for="group in groupsFiltered" :key="group.parent">
        <h3
          class="sans-serif ttu mb1 collection-sidebar-menu__filters__label sidebar-title accordion-head"
          for="subMenu"
          aria-expanded="false"
        >
          {{ group.parent }}
        </h3>
        <div class="accordion-body visuallyhidden">
          <ul class="list pt2 pb2 grid grid-flow-col gap-3">
            <li v-for="(label, index) in group.labels" :key="label">
              <label
                v-if="index < group.maxTags"
                class="list ttc collection-sidebar-menu__filters__sublabel"
                :for="label"
                aria-expanded="false"
              >
                <input
                  :id="label"
                  class="collection-sidebar-menu__filters__input"
                  type="checkbox"
                  :name="label"
                  :value="group.tags[index]"
                  :checked="isChecked(group.tags[index])"
                  :data-group="group.parent"
                  @change="addFilter"
                />
                {{ label }}
              </label>
            </li>
          </ul>
          <button
            v-if="group.tags.length > group.maxTags"
            class="btn-secondary-beige link collection-sidebar-menu__filters__view-more"
            @click="viewMore(group.label, group.tags)"
          >
            View More
          </button>
        </div>
        <hr />
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="(filter, index) in selectedFilters.everyLabelSelected"
          :key="filter"
          class="collection-sidebar-menu__selected-filters"
        >
          <span
            :id="filter"
            class="collection-sidebar-menu__selected-filters-name"
            :name="filter"
            :value="selectedFilters.everyTagSelected[index]"
          >
            {{ filter }}
          </span>
          <button
            class="collection-sidebar-menu__selected-filters-button"
            @click="
              removeSpecificFilter(
                filter,
                selectedFilters.everyTagSelected[index]
              )
            "
          >
            <svg
              class="svg-icon icon-close"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 14 14"
            >
              <title>Close Icon</title>
              <path
                fill="currentColor"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.9394 6.53033L0.469727 1.06066L1.53039 0L7.00006 5.46967L12.4697 0L13.5304 1.06066L8.06072 6.53033L13.5304 12L12.4697 13.0607L7.00006 7.59099L1.53039 13.0607L0.469727 12L5.9394 6.53033Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <br />
      <button
        v-if="Object.entries(activeFilters).length > 0"
        class="btn-primary link dib collection-sidebar-menu__filters__remove"
        @click="removeFilter"
      >
        Remove All Filters
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      productsEls: [],
      groupsFiltered: [],
      activeFilters: {},
      initialFiltersToLoad: 10,
      selectedFilters: [],
      activeLabels: {},
    };
  },
  computed: {
    is_mobile: function () {
      const isMobile = window.matchMedia("(max-width: 719px)");
      return isMobile.matches ? true : false;
    },
  },
  watch: {
    activeFilters: {
      handler: function () {
        let foundItems = false;

        if (Object.keys(this.activeFilters).length > 0) {
          this.productsEls.forEach((product) => {
            try {
              const tags = JSON.parse(product.dataset.productTags);

              const foundTags = this.foundSelectedTagsInProduct(tags);

              foundItems = this.productWithAllTagsCategories(
                product,
                foundTags,
                foundItems
              );
            } catch (e) {
              console.log(e, "could not parse tags json for item", product);
            }
          });

          this.showCollectionTitles();

          if (!foundItems) {
            this.notFoundEl.classList.remove("hidden");
          } else {
            this.notFoundEl.classList.add("hidden");
          }
        } else {
          this.removeFilter();
        }
      },
      deep: true,
    },
  },
  created() {
    this.productsEls = document.querySelectorAll("[data-product-item]");
    this.collectionTitles = document.querySelectorAll(
      ".featured-collection--hero"
    );
    this.notFoundEl = document.querySelector("[data-not-found]");
    this.collectionWrapper = document.querySelectorAll(
      ".featured-collection--wrapper"
    );

    let allTags = [];
    this.productsEls.forEach((item) => {
      try {
        const tags = JSON.parse(item.dataset.productTags);
        allTags.push(...tags);
      } catch (e) {
        console.log(e, "could not parse tags json for item", item);
      }
    });
    allTags = [...new Set(allTags)];

    this.groupsFiltered = this.groups.reduce((acc, group) => {
      let indexToFilter = [];

      let labels = [];

      const tags = group.tags.filter((tag, index) => {
        if (allTags.includes(tag)) {
          indexToFilter.push(index);
          labels.push(group.labels[index]);
          return true;
        }
        return false;
      });

      let maxTags = this.initialFiltersToLoad;

      if (tags.length !== 0 || labels.length !== 0) {
        acc.push({ ...group, tags, maxTags, labels });
      }
      return acc;
    }, []);
  },
  methods: {
    isChecked: function (value) {
      return Object.values(this.activeFilters).find((a) => {
        return a.find((el) => el === value);
      });
    },

    addFilter(e) {
      const { checked, value, dataset, name } = e.target;

      if (checked) {
        if (!this.activeFilters[dataset.group]) {
          this.activeFilters[dataset.group] = [];
        }
        if (!this.activeLabels[dataset.group]) {
          this.activeLabels[dataset.group] = [];
        }

        this.activeFilters[dataset.group].push(value);
        this.activeLabels[dataset.group].push(name);
      } else {
        this.activeFilters[dataset.group] = this.activeFilters[
          dataset.group
        ].filter((el) => el !== value);

        this.activeLabels[dataset.group] = this.activeLabels[
          dataset.group
        ].filter((el) => el !== name);

        if (this.activeFilters[dataset.group].length === 0) {
          delete this.activeFilters[dataset.group];
        }
        if (this.activeLabels[dataset.group].length === 0) {
          delete this.activeFilters[dataset.group];
        }
      }
      this.showSelectedFilters();
      this.showCollectionTitles();
    },

    showSelectedFilters() {
      let everyTagSelected = Object.values(this.activeFilters).reduce(
        (acc, tags) => {
          tags.forEach((tag) => acc.push(tag));
          return acc;
        },
        []
      );

      let everyLabelSelected = Object.values(this.activeLabels).reduce(
        (acc, labels) => {
          labels.forEach((label) => acc.push(label));
          return acc;
        },
        []
      );

      if (everyTagSelected.length > 0) {
        this.selectedFilters = { everyTagSelected, everyLabelSelected };
      }

      this.showCollectionTitles();
    },

    showCollectionTitles() {
      if (!this.collectionTitles || this.collectionTitles.length === 0) {
        return;
      }
      this.collectionTitles.forEach((title) =>
        title.classList.remove("hidden")
      );

      this.collectionWrapper.forEach((collection) => {
        let listOfItems = collection.querySelectorAll("li:not(.hidden)");
        if (listOfItems.length === 0) {
          const titleHero = collection.querySelector(
            ".featured-collection--hero"
          );
          titleHero.classList.add("hidden");
        }
      });
    },

    foundSelectedTagsInProduct(tags) {
      return Object.values(this.activeFilters).reduce((acc, filterTags) => {
        const found = filterTags.find((filterTag) => tags.includes(filterTag));
        if (found) {
          acc.push(found);
        }
        return acc;
      }, []);
    },

    productWithAllTagsCategories(product, foundTags, foundItems) {
      if (Object.keys(this.activeFilters).length === foundTags.length) {
        foundItems = true;
        product.classList.remove("hidden");
      } else {
        product.classList.add("hidden");
      }
      return foundItems;
    },

    removeFilter() {
      this.productsEls.forEach((product) => {
        product.classList.remove("hidden");
      });
      if (!this.collectionTitles || this.collectionTitles.length > 0) {
        this.collectionTitles.forEach((title) =>
          title.classList.remove("hidden")
        );
      }
      Object.keys(this.activeFilters).forEach((key) => {
        delete this.activeFilters[key];
      });
      Object.keys(this.activeLabels).forEach((key) => {
        delete this.activeLabels[key];
      });
      this.notFoundEl.classList.add("hidden");
      this.selectedFilters = [];
    },

    removeSpecificFilter(label, tag) {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = this.activeFilters[key].filter(
          (el) => el !== tag
        );
        if (this.activeFilters[key].length === 0) {
          delete this.activeFilters[key];
        }
      });

      Object.keys(this.activeLabels).forEach((key) => {
        this.activeLabels[key] = this.activeLabels[key].filter(
          (el) => el !== label
        );
        if (this.activeLabels[key].length === 0) {
          delete this.activeLabels[key];
        }
      });

      this.showSelectedFilters();
      this.showCollectionTitles();
    },

    viewMore(e) {
      this.groupsFiltered.forEach((group) => {
        if (group.label === e) {
          group.maxTags += 10;
        }
      });
    },
  },
};
</script>
