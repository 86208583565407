/**
 * state
 */
const state = {
  visible: false,
  isInCart: false,
  subInCart: false,
};

/**
 * getters
 */
const getters = {};

/**
 * mutations
 */
const mutations = {
  TOGGLE(state) {
    state.visible = !state.visible;
  },

  SHOW(state) {
    state.visible = true;
  },

  HIDE(state) {
    state.visible = false;
  },

  IS_IN_CART(state, payload) {
    state.isInCart = payload;
  },

  SUB_IN_CART(state, payload) {
    state.subInCart = payload;
  },
};

/**
 * actions
 */
const actions = {
  toggle({ commit }) {
    commit("TOGGLE");
  },

  show({ commit }) {
    const isInCart = window.localStorage.getItem("gwpAdded");
    // console.log({isInCart})
    if (isInCart !== "yes") {
      commit("SHOW");
      commit("IS_IN_CART", false);
    } else {
      commit("IS_IN_CART", true);
    }
  },

  hide({ commit }) {
    commit("HIDE");
  },

  setIsInCart({ commit }, payload) {
    if (payload) {
      window.localStorage.setItem("gwpAdded", "yes");
    } else {
      window.localStorage.removeItem("gwpAdded");
    }
    commit("IS_IN_CART", payload);
  },

  setSubInCart({ commit }, payload) {
    commit("SUB_IN_CART", payload);
  },

  addToCart({ commit }, payload) {
    if (window.$) {
      const $ = window.$;
      $.ajax({
        type: "POST",
        url: "/cart/add.js",
        data: {
          id: payload.id,
          quantity: 1,
          properties: {
            _gwp: true,
          },
        },
        success: () => {
          // refresh cart
          window.__bocces?.miniCart?.refresh();
          // hide free gift
          commit("HIDE");
          window.localStorage.setItem("gwpAdded", "yes");
          commit("IS_IN_CART", true);

          if (payload.discountCode) {
            $.ajax({
              type: "GET",
              url: `/discount/${payload.discountCode}`,
            });
          }
        },
      });
    }
  },
};

/**
 * export
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
