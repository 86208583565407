<template>
  <div class="baskets-options pr3 pl3">
    <h3>{{ titleOne }}</h3>
    <p class="italic content">
      {{ subTitleOne }}
    </p>
    <div class="baskets-options__products">
      <button
        v-for="p in products"
        :key="p.id"
        :disabled="!p.available"
        class="baskets-options__product"
        :class="{
          'baskets-options__product--selected': currentProduct.id === p.id,
        }"
        @click="selectProduct(p)"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div class="baskets-options__product-icon" v-html="getIcon(p)" />
        <!--eslint-enable-->
        <span>
          <span class="ttu f6 tracked-mega fw9">{{
            p.basketInfo.shortTitle
          }}</span>
          <br />
          <span class="f--7">{{ p.basketInfo.size }}</span>
        </span>
      </button>
    </div>

    <h3>
      {{ titleTwo }} <span class="fr italic">{{ titleTwoPrice }}</span>
    </h3>
    <p class="italic content">
      {{ subTitleTwo }}
    </p>
    <div
      v-if="
        currentProduct && currentProduct.options && currentProduct.options[0]
      "
      class="baskets-options__product-variants"
    >
      <button
        v-for="option in supplements"
        :key="option"
        :disabled="
          !isOptionAvailable(currentProduct.options[0].position, option)
        "
        class="baskets-options__option"
        :class="{
          'baskets-options__option--selected': isOptionSelected(
            currentProduct.options[0].position,
            option
          ),
          'baskets-options__option--disabled': !isOptionAvailable(
            currentProduct.options[0].position,
            option
          ),
        }"
        @click="selectSupplement(currentProduct.options[0].position, option)"
      >
        {{ option }}
      </button>
    </div>

    <hr />
    <div>
      <div class="row">
        <p>Shipping</p>
        <p class="italic">
          {{ shipping }}
        </p>
      </div>
      <div class="row">
        <p>Total (billed monthly)</p>
        <p v-if="currentProduct" class="italic">
          {{ formatPrice(currentVariant.price) }}
        </p>
      </div>
    </div>

    <!-- opt out of beef -->
    <div
      v-if="
        currentProduct && currentProduct.options && currentProduct.options[0]
      "
      class="baskets-options__product-variants"
    >
      <button
        v-for="option in dietary"
        :key="option"
        class="baskets-options__option italic mt3"
        :class="{
          'baskets-options__option--selected': isOptionSelected(
            currentProduct.options[1].position,
            option
          ),
        }"
        @click="selectStyle(currentProduct.options[1].position, option)"
      >
        {{ opt }}
      </button>
    </div>

    <button
      class="btn-blue mt4"
      :class="{ 'disable-button': isLoading }"
      :disabled="isLoading"
      @click="addToCart"
    >
      {{ btn }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    titleOne: {
      type: String,
      default: "Choose your pup's size!",
    },
    subTitleOne: {
      type: String,
      default: "We'll pick the Brushy Sticks that fit!",
    },
    titleTwo: {
      type: String,
      default: "Want to add on a supplement?",
    },
    titleTwoPrice: {
      type: String,
      default: "+$20",
    },
    subTitleTwo: {
      type: String,
      default: "OPTIONAL: Choose your pup's fav if you want to save 15%",
    },
    shipping: {
      type: String,
      default: "On us!",
    },
    opt: {
      type: String,
      default: "Opt me out of Poultry & Beef",
    },
    btn: {
      type: String,
      default: "Get my monthly basket",
    },
    supplementDefaultOption: {
      type: String,
      default: "",
    },
    styleDefaultOption: {
      type: String,
      default: "",
    },
    products: {
      type: Array,
      required: true,
    },
    currentPdpHandle: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentProduct: null,
    options: [],
    isLoading: false,
  }),
  computed: {
    supplements() {
      return this.currentProduct.options[0].values.filter(
        (v) => v !== this.supplementDefaultOption
      );
    },
    dietary() {
      return this.currentProduct.options[1].values.filter(
        (v) => v !== this.styleDefaultOption
      );
    },
    currentVariant() {
      // based on selected options we should be able to pick a variant from the current product
      let variant = null;
      const option1 = this.options.find((o) => o.position === 1);
      const option2 = this.options.find((o) => o.position === 2);
      variant = this.currentProduct.variants.find((v) => {
        return v.option1 === option1.value && v.option2 === option2.value;
      });

      return variant;
    },
  },
  created() {
    if (this.products.length > 0) {
      // see if one of the products matches the product that they are currently on
      const pdpProduct = this.products.find(
        (p) => p.handle === this.currentPdpHandle
      );
      if (pdpProduct) {
        this.currentProduct = pdpProduct;
      } else {
        this.currentProduct = this.products[0];
      }
      // get first variant and set those options
      this.setDefaultOptions();
    }
  },
  methods: {
    isOptionAvailable(position, value) {
      let variant = null;
      const option2 = this.options.find((o) => o.position === 2);
      variant = this.currentProduct.variants.find((v) => {
        return v.option1 === value && v.option2 === option2.value;
      });
      return variant?.available;
    },
    getIcon(product) {
      if (product?.basketInfo?.icon) {
        return `${window.decodeURI(product?.basketInfo?.icon)}`;
      } else {
        return null;
      }
    },
    formatPrice(price) {
      return `$${price / 100}`;
    },
    setDefaultOptions() {
      this.options.push({
        position: 1,
        value: this.currentProduct.variants[0].option1,
      });
      this.options.push({
        position: 2,
        value: this.currentProduct.variants[0].option2,
      });
    },
    selectProduct(product) {
      this.currentProduct = product;
    },
    selectSupplement(position, value) {
      const found = this.options.find((o) => {
        return o.position === position;
      });
      if (found) {
        if (found.value === value) {
          found.value = this.supplementDefaultOption;
        } else {
          found.value = value;
        }
      } else {
        this.options.push({
          position,
          value,
        });
      }
    },
    selectStyle(position, value) {
      const found = this.options.find((o) => {
        return o.position === position;
      });
      if (found) {
        if (found.value === value) {
          found.value = this.styleDefaultOption;
        } else {
          found.value = value;
        }
      } else {
        this.options.push({
          position,
          value,
        });
      }
    },
    isOptionSelected(position, value) {
      return this.options.find(
        (o) => o.position === position && o.value === value
      );
    },
    addToCart() {
      this.isLoading = true;
      const payload = {
        quantity: "1",
        id: this.currentVariant.id,
        properties: {
          shipping_interval_frequency:
            this.currentProduct.subscriptions.shipping_interval_frequency,
          shipping_interval_unit_type:
            this.currentProduct.subscriptions.shipping_interval_unit_type,
        },
        purchase_type: "autodeliver",
      };

      if (window.$) {
        window.$.ajax({
          type: "POST",
          url: "/cart/add.js",
          data: payload,
          success: () => {
            this.isLoading = false;
            if (window.__bocces && window.__bocces.miniCart) {
              window.__bocces.miniCart.refresh();
              window.__bocces.miniCart.open();
            }
          },
          dataType: "json",
        });
      }
    },
  },
};
</script>

<style>
.baskets-options {
  text-align: left;
}

.baskets-options h3 {
  font-family: "Brandon Grotesque", -apple-system, "system-ui", "avenir next",
    avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  padding-bottom: 0px;
  margin: 0;
  font-weight: 900;
  letter-spacing: 1.5px;
  padding-top: 12px;
}

.baskets-options p {
  font-family: "Brandon Grotesque", -apple-system, "system-ui", "avenir next",
    avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  font-size: 14px;
  padding-bottom: 12px;
  margin: 0;
  font-weight: 500;
}

.baskets-options p.content {
  font-size: 1.15rem;
  padding-top: 1px;
}

.baskets-options .italic {
  font-style: italic;
}

.baskets-options .row {
  display: flex;
  justify-content: space-between;
}

.baskets-options .row p {
  font-size: 17px;
  padding-bottom: 8px;
}

.baskets-options hr {
  margin-top: 40px;
  border-color: #231f20;
}

.baskets-options__products {
  margin-bottom: 24px;
  margin-left: -28px;
  margin-right: -28px;
  display: flex;
}

.baskets-options__product {
  position: relative;
  background: none;
  border: none;
  /* width: auto; */
  /* margin: 0 22px; */
  padding-bottom: 20px;
  text-align: center;
  padding-right: 6px;
  padding-left: 6px;
  width: 33%;
}

.baskets-options__product-icon,
.baskets-options__product::after {
  opacity: 0.2;
}

.baskets-options__product-icon {
  height: 36px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 3px;
}

.baskets-options__product::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border: 1px black solid;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
  border-radius: 99999px;
}

.baskets-options__product.baskets-options__product--selected
  .baskets-options__product-icon,
.baskets-options__product.baskets-options__product--selected::after {
  opacity: 1;
}
.baskets-options__product.baskets-options__product--selected::after {
  background: black;
}

.baskets-options__product-variants {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
}

.baskets-options__option {
  position: relative;
  background: none;
  border: none;
  padding-left: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 500;
}

.baskets-options__option--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.baskets-options__option::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 10px;
  width: 10px;
  border: solid 1px black;
}

.baskets-options__option--selected::before {
  content: url("data:image/svg+xml,%3Csvg viewBox='0 0 106 110' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10'%3E%3Cpath d='m0 3.898 2.023 3.667L10.7-3.667' style='fill:none;fill-rule:nonzero;stroke:%23030405;stroke-width:1.91px' transform='translate(7.962 38.51) scale(8.33333)'/%3E%3C/svg%3E");
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-90%);
  width: 15px;
  height: 15px;
}

.disable-button {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-blue {
  font-family: "Brandon Grotesque", -apple-system, BlinkMacSystemFont,
    "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto,
    "segoe ui", arial, sans-serif;
  position: relative;
  display: block;
  padding: 17px 24px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) background-color,
    0.15s cubic-bezier(0.4, 0, 0.2, 1) border-color;
  color: #000;
  background-color: #b9d9ec;
  border: 3px solid #b9d9ec;
  border-radius: 0;
  width: auto;
  margin: 0 auto;
}
</style>
