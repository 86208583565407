<template>
  <div class="accordion">
    <!-- Desktop Tabs -->
    <div class="mb-20 flex-row border-b border-b-black accordion-desktop">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        @click="toggleTab(index)"
        class="relative cursor-pointer py-5 text-sm accordion__item"
        :class="{
          active: isTabOpen(index),
        }"
      >
        {{ tab }}
      </div>
    </div>
    <div v-if="isAnyTabOpen" class="accordion-desktop">
      <div class="tab__content pb-10 max-sm:text-base">
        <slot :name="normalizeSlotName(tabs[openTabs[0]])"></slot>
      </div>
    </div>

    <!-- Mobile Accordion -->
    <div class="accordion-mob">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="accordion__item border-b-darkBlue border-b-[3px] last:border-b-0"
      >
        <div
          @click="toggleTab(index)"
          class="accordion__header relative flex cursor-pointer items-center justify-between border-b border-transparent"
          :class="{
            ' border-b-black': isTabOpen(index),
          }"
        >
          <span
            class="text-darkBlue relative py-15 text-[20px] font-bold uppercase max-sm:text-base"
            :class="{
              ' border-b-black  font-bionM before:absolute before:bottom-[-1px] before:left-0 before:z-5 before:h-5 before:w-full before:bg-pastelPinkL':
                isTabOpen(index),
            }"
            >{{ tab }}
          </span>
          <span :class="{ 'rotate-180': isTabOpen(index) }">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
            >
              <path
                d="M1 1L9 9L17 1"
                stroke="#3F3F3F"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </div>
        <div
          v-if="isTabOpen(index)"
          class="accordion__content max-sm:text-base"
        >
          <slot :name="normalizeSlotName(tab)"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  data() {
    return {
      openTabs: [0],
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isAnyTabOpen() {
      return this.openTabs.length > 0;
    },
  },
  methods: {
    toggleTab(index) {
      if (this.openTabs.includes(index)) {
        this.openTabs = [];
      } else {
        this.openTabs = [index];
      }
    },
    isTabOpen(index) {
      return this.openTabs.includes(index);
    },
    normalizeSlotName(name) {
      return name.replace(/\s+/g, "_").toLowerCase();
    },
  },
};
</script>
