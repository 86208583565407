<template>
  <div class="gwp-progress-bar">
    <div class="gwp-progress-bar__bar-bg" :style="{ backgroundColor: bgColor }">
      <div
        class="gwp-progress-bar__bar"
        :style="{ backgroundColor: barColor, width: progressCss }"
      />
    </div>
    <p class="gwp-progress-bar__copy">
      {{ currentCopy }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    threshold: {
      type: Number,
      required: true,
    },
    bgColor: {
      type: String,
      default: "#ccc",
    },
    barColor: {
      type: String,
      default: "#000",
    },
    copyEmpty: {
      type: String,
      default: "GWP empty state copy",
    },
    copyApproaching: {
      type: String,
      default: "GWP approaching state copy",
    },
    copyCongrats: {
      type: String,
      default: "GWP congrats state copy",
    },
    copyAddedGift: {
      type: String,
      default: "GWP in cart state copy",
    },
    copySubError: {
      type: String,
      default: "GWP doesn't work with Subscriptions",
    },
    oneprice: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    price: 0,
    isLoading: false,
    subscriptionInCart: false,
  }),
  computed: {
    progress() {
      return (this.price / 100 / this.threshold) * 100;
    },
    progressCss() {
      return `${this.progress}%`;
    },
    gwpIsInCart() {
      return this.$store.state.gwp?.isInCart;
    },
    currentCopy() {
      if (this.subscriptionInCart && this.progress === 0) {
        return this.copySubError;
      } else if (this.progress === 0) {
        return this.copyEmpty;
      } else if (this.progress < 100) {
        const spendMore = this.threshold - this.price / 100;
        if (spendMore % 1 === 0) {
          return this.copyApproaching.replace("[XX]", spendMore);
        } else {
          return this.copyApproaching.replace("[XX]", spendMore.toFixed(2));
        }
      } else if (this.gwpIsInCart) {
        return this.copyAddedGift;
      } else {
        return this.copyCongrats;
      }
    },
  },
  watch: {
    progress(newVal) {
      // console.log(newVal, this.subscriptionInCart);
      if (newVal >= 100) {
        this.$store.dispatch("gwp/show");
      } else {
        this.$store.dispatch("gwp/hide");
      }
    },
  },
  created() {
    // this ONLY runs in atlantic js for refreshing the cart method
    this.refreshComponent();

    if (window.__bocces) {
      if (window.__bocces.vueMethods) {
        window.__bocces.vueMethods.updateGWPPrice = this.refreshComponent;
      } else if (!window.__bocces.vueMethods) {
        window.__bocces.vueMethods = {
          updateGwpBar: this.refreshComponent,
        };
      }
    }
  },
  methods: {
    refreshComponent() {
      const cartMessage = document.querySelector("#gwp-cart-message");
      const cartMessageFreeGiftRemoval = document.querySelector(
        "#gwp-cart-message-sub-removal"
      );
      const $ = window.$;

      if ($ && !this.isLoading) {
        $.get("/cart.json", (data) => {
          this.isLoading = false;
          let isInCart = false;
          let gwpLineItem = null;
          let newTotal = 0;
          let gwpPrice = 0;
          const uniqueProductIds = new Set();

          data.items?.forEach((item) => {
            const isRechargeProduct = item?.selling_plan_allocation;

            if (!isRechargeProduct) {
              if (this.oneprice) {
                if (!uniqueProductIds.has(item.id)) {
                  newTotal += item.price;
                  uniqueProductIds.add(item.id);
                }
              } else {
                newTotal += item.price * item.quantity;
              }
            }

            if (item?.properties?._gwp === "true") {
              isInCart = true;
              gwpLineItem = item;
              gwpPrice = item.price;
            }

            if (
              item?.properties?.shipping_interval_frequency ||
              item?.properties?.shipping_interval_unit_type
            ) {
              this.subscriptionInCart = true;
            }
          });

          this.$store.dispatch("gwp/setIsInCart", isInCart);

          if (this.subscriptionInCart) {
            this.$store.dispatch("gwp/setSubInCart", true);
            if (cartMessageFreeGiftRemoval) {
              cartMessageFreeGiftRemoval.classList.remove("hidden");
            }
          } else {
            this.$store.dispatch("gwp/setSubInCart", false);
          }

          const isStillOverThreshold =
            this.threshold * 100 <= newTotal - gwpPrice;

          if (gwpLineItem) {
            if (cartMessage) {
              cartMessage.classList.remove("hidden");
            }
            if (!isStillOverThreshold) {
              const updates = {
                [gwpLineItem.key]: 0,
              };

              $.ajax({
                type: "POST",
                url: "/cart/update.js",
                data: {
                  updates,
                },
                success: () => {
                  window.__bocces?.miniCart?.refresh();
                  window.localStorage.removeItem("gwpAdded");
                  if (cartMessage) {
                    cartMessage.classList.add("hidden");
                  }
                  if (cartMessageFreeGiftRemoval) {
                    cartMessageFreeGiftRemoval.classList.add("hidden");
                  }
                },
              });
            }
          } else if (
            this.progress >= 100 &&
            !gwpLineItem &&
            !this.subscriptionInCart
          ) {
            this.$store.dispatch("gwp/show");
          }

          this.price = newTotal - gwpPrice;
        });
      }
    },

    // refreshComponent() {
    //   const cartMessage = document.querySelector("#gwp-cart-message");
    //   const cartMessageFreeGiftRemoval = document.querySelector(
    //     "#gwp-cart-message-sub-removal"
    //   );
    //   const $ = window.$;

    //   if ($ && !this.isLoading) {
    //     $.get("/cart.json", (data) => {
    //       this.isLoading = false;
    //       let isInCart = false;
    //       let gwpLineItem = null;
    //       let newTotal = 0.1; // Initialize newTotal to accumulate the prices of non-recharge products
    //       this.subscriptionInCart = false;

    //       // Iterate through cart items
    //       data.items?.forEach((item) => {
    //         // Check if it's a GWP item
    //         if (item?.properties?._gwp === "true") {
    //           isInCart = true;
    //           gwpLineItem = item;
    //         }

    //         // Check if the product has `selling_plan_allocation` (indicating it's a subscription/recharge product)
    //         const isRechargeProduct = item?.selling_plan_allocation;
    //         const isGwp = item?.properties?._gwp;
    //         console.log(!item.selling_plan_allocation);
    //         if (!isRechargeProduct && !isGwp) {
    //           newTotal += item.final_price || item.price;
    //         } else {
    //           this.subscriptionInCart = true;
    //         }
    //       });

    //       // Update the GWP state based on subscription products in the cart
    //       this.$store.dispatch("gwp/setIsInCart", isInCart);
    //       this.$store.dispatch("gwp/setSubInCart", this.subscriptionInCart);

    //       // Check if the new total is still over the threshold
    //       const isStillOverThreshold = this.threshold * 100 <= newTotal;

    //       // If GWP is in the cart and the total is below the threshold, remove GWP
    //       if (gwpLineItem && !isStillOverThreshold) {
    //         const updates = {
    //           [gwpLineItem.key]: 0, // Remove the GWP item
    //         };

    //         $.ajax({
    //           type: "POST",
    //           url: "/cart/update.js",
    //           data: { updates },
    //           success: () => {
    //             // Refresh cart
    //             window.__bocces?.miniCart?.refresh();
    //             // Remove localstorage
    //             window.localStorage.removeItem("gwpAdded");

    //             if (cartMessage) {
    //               cartMessage.classList.add("hidden");
    //             }
    //             if (cartMessageFreeGiftRemoval) {
    //               cartMessageFreeGiftRemoval.classList.add("hidden");
    //             }
    //           },
    //         });
    //       } else {
    //         // If GWP should remain or is not in the cart, show the appropriate messages
    //         if (cartMessage && gwpLineItem) {
    //           cartMessage.classList.remove("hidden");
    //         }
    //         if (this.subscriptionInCart && cartMessageFreeGiftRemoval) {
    //           cartMessageFreeGiftRemoval.classList.remove("hidden");
    //         }
    //       }

    //       if (!newTotal) {
    //         this.price = data.total_price;
    //       } else {
    //         this.price = newTotal;
    //       }
    //     });
    //   }
    // },
  },
};
</script>

<style>
.gwp-progress-bar {
  text-align: center;
  font-size: 13px;
  padding-top: 13px;
}

.gwp-progress-bar__bar-bg {
  border-radius: 2px;
  overflow: hidden;
}

.gwp-progress-bar__bar {
  height: 4px;
}

.gwp-progress-bar__copy {
  margin-top: 10px;
}
</style>
